<template>
  <vs-card>
    <vs-form autocomplete="off">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="!errors.first('basic.name') && supplierData.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.name') ? true : false"
              v-validate="'required|min:2|max:150|alpha_spaces'" name="name" data-vv-as="Name" label="Supplier Name"
              class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.name')"
              v-model="supplierData.name" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required'" name="email" data-vv-as="Email" label="Email Address" class="w-full"
              v-model="supplierData.email" />
            <span class="text-danger" style="font-size: 0.85em">{{
                errors.first("email")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.contactNumber') &&
              supplierData.contactNumber != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.contactNumber') ? true : false" v-validate="'required'" name="contactNumber"
              data-vv-as="Contact Number" label="Contact Number" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.contactNumber')" v-model="supplierData.contactNumber" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Delivery Details</h1>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.deliveryFee') &&
              supplierData.deliveryDetails.deliveryFee != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.deliveryFee') ? true : false" v-validate="'required|decimal:3'"
              name="deliveryFee" data-vv-as="Delivery Fee" label="Delivery Fee" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.deliveryFee')" v-model="supplierData.deliveryDetails.deliveryFee" />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Settings</h1>
            <h6 class="mt-5">Public or private supplier</h6>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full ml-5">
            <vs-radio v-model="privateStatus" vs-value="false"><strong>Public</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12">
            <p>
              Public suppliers allows all accounts to view products, but cannot
              purchase unless set in the account settings.
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full mt-5 ml-5">
            <vs-radio v-model="privateStatus" vs-value="true"><strong>Private</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12">
            <p>
              Private suppliers and all their products are hidden from the
              store. Only accounts associated to this supplier can view and
              purchase their products.
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12" v-if="privateStatus == 'true'">
          <div class="w-1/2 mt-5 ml-12 mb-5">
            <label class="font-bold">Select Clinic</label>
            <!--  <v-select
              multiple
              :closeOnSelect="false"
              label="clinicName"
              :options="clinicList"
              value="value"
              :reduce="option => option._id"
              v-model="supplierData.privateAssignedClinics"
              data-vv-as="AssignedClinics"
              data-vv-scope="basic"
              name="privateAssignedClinics"
              class="w-full vs-con-input"
            >
            </v-select> -->
            <v-select multiple :options="clinicList" :closeOnSelect="false" :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="clinicFilter" data-vv-as="Clinic" />
            <br />
            <span class="text-danger" style="font-size: 0.85em">{{
                errors.first("privateAssignedClinics")
            }}</span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5" align="right">
            <vs-button :disabled="!validateForm" @click="saveDetails" icon-pack="feather" class="mr-2">Update Supplier
            </vs-button>
            <vs-button color="danger" @click="cancelHandler(supplierData._id)" icon-pack="feather">Cancel</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import axios from "@/axios";
import SupplierTrackingList from "./SupplierTrackingList";

export default {
  name: "EditSupplier",
  components: {
    vSelect,
    SupplierTrackingList,
  },
  props: {
    supplierId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.supplierId,
      supplierData: {},
      isValid: false,
      emailArr: [],
      trackingOptions: [],
      trackingError: "",
      itemToRemove: [],
      privateStatus: "",
      clinicList: [],
      clinicFilter: [],
      privateAssignedClinics: true,
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.supplierData.email.length !== 0 &&
        this.supplierData.name !== "" &&
        this.supplierData.contactNumber !== "" &&
        this.supplierData.deliveryDetails.deliveryFee !== ""
      );
    },
  },
  mounted() { },
  methods: {
    ...mapActions("supplier", ["getSupplierDetails"]),
    ...mapActions("clinic", ["fetchAllClinic"]),

    fetchSupplierDetails(id) {
      this.$vs.loading();
      this.getSupplierDetails(id).then((res) => {
        this.supplierData = res.data.data;
        this.supplierData.deliveryDetails.deliveryFee = (
          this.supplierData.deliveryDetails.deliveryFee / 100
        ).toFixed(3);

        this.supplierData.privateStatus =
          this.supplierData.privateStatus == undefined ||
            this.supplierData.privateStatus == null
            ? false
            : this.supplierData.privateStatus;

        this.supplierData.privateAssignedClinics =
          this.supplierData.privateAssignedClinics == undefined ||
            this.supplierData.privateAssignedClinics.length < 1
            ? []
            : this.supplierData.privateAssignedClinics;

        if (this.supplierData.privateAssignedClinics.length > 0) {
          let filterArr = [];
          let privateClinics = this.supplierData.privateAssignedClinics;
          privateClinics.forEach(async (item) => {
            let clinic = await this.clinicList.find(
              (clinicObj) => clinicObj.value === item
            );
            await filterArr.push(clinic);
          });
          this.clinicFilter = filterArr;
        }
        this.privateStatus =
          this.supplierData.privateStatus == true ? "true" : "false";
        this.$vs.loading.close();
      });
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png,image/jpeg",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "frontImage") {
            self.supplierData.paymentMethod.frontImage = res.data.Location;
          }
          if (key === "backImage") {
            self.supplierData.paymentMethod.backImage = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    async getAllClinic() {
      this.$vs.loading();
      await this.fetchAllClinic().then(async (res) => {
        const data = res.data.data;
        this.clinicList = await data.map((item) => {
          return {
            label: item.clinicName,
            value: item._id,
          };
        });
        this.$vs.loading.close();
      });
    },
    async saveDetails() {
      /* eslint-disable */
      this.isValid = await this.$validator.validateAll("basic");
      // this.isValid = await this.$refs.stepTwoRef.$validator.validate();

      if (
        this.supplierData.privateStatus === true &&
        this.supplierData.privateAssignedClinics.length === 0
      ) {
        this.privateAssignedClinics = false;
        this.errors.add({
          field: "privateAssignedClinics",
          msg: "Clinic/s must be assigned if supplier is private",
        });
      } else {
        this.privateAssignedClinics = true;
        this.errors.remove("privateAssignedClinics");
      }

      if (!this.isValid || !this.privateAssignedClinics) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }

      if (this.emailArr.length > 0) {
        this.supplierData.email = this.emailArr;
      }
      this.supplierData.trackingOptions = this.trackingOptions;
      if (this.itemToRemove && this.itemToRemove.length > 0)
        this.supplierData.itemToRemove = this.itemToRemove;

      this.$emit("editDetail", this.supplierData);
    },
    cancelHandler(id) {
      this.$emit("cancelEdit", id);
    },
    changed(data) {
      this.trackingOptions = data;
    },
    remove(id) {
      this.itemToRemove.push(id);
    },
  },
  watch: {
    "supplierData.email": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        let regex =
          /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        this.emailArr = this.supplierData.email.split(",");
        this.emailArr.forEach((item) => {
          if (item === "" || !regex.test(item.replace(/\s/g, ""))) {
            this.isValid = false;
          }
          if (this.isValid === false) {
            this.errors.add({
              field: "email",
              msg: "The Email field format is invalid",
            });
          }
        });
      }
    },
    privateStatus: function (val) {
      if (val == "true") {
        this.supplierData.privateStatus = true;
      } else {
        this.supplierData.privateStatus = false;
        this.supplierData.privateAssignedClinics = [];
        this.errors.remove("privateAssignedClinics");
      }
    },
    /*  "supplierData.privateAssignedClinics": function (val) {
      console.log("pricsdf", this.supplierData.privateAssignedClinics);
      if (
        this.supplierData.privateStatus === true &&
        this.supplierData.privateAssignedClinics.length > 0
      ) {
        this.privateAssignedClinics = true;
        this.errors.remove("privateAssignedClinics");
      }
    }, */
    async clinicFilter(val) {
      if (this.supplierData.privateStatus === true && val.length > 0) {
        this.supplierData.privateAssignedClinics = await val.map(
          (item) => item.value
        );
        this.privateAssignedClinics = true;
        this.errors.remove("privateAssignedClinics");
        console.log("after", this.supplierData.privateAssignedClinics);
      }
    },
  },
  async created() {
    await this.getAllClinic();
    this.id = this.supplierId;
    await this.fetchSupplierDetails(this.id);
  },
};
</script>

<style scoped>
</style>
